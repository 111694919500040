<template lang="pug">
	main#faleConosco
		Banner(:contents="banner")
		Cards
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner'
import Cards from '@sections/Contato/Cards/Cards'

export default {
	name: "view-contato",
	components: {
		Banner,
		Cards
	},
	data(){
		return {
			banner: {
				banner: {
					alt: `Trabalhe Conosco`,
					url: require(`@images/paginas/banner-fale-conosco.jpg`),
				},
				title: `Contato`
			},
			contents: []
		}
	},
	metaInfo() {
		return {
			title: 'contato',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Contato.styl"></style>
