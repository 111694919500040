import { render, staticRenderFns } from "./Cards.vue?vue&type=template&id=b143cc34&scoped=true&lang=pug&"
import script from "./Cards.vue?vue&type=script&lang=js&"
export * from "./Cards.vue?vue&type=script&lang=js&"
import style0 from "./Cards.styl?vue&type=style&index=0&id=b143cc34&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b143cc34",
  null
  
)

export default component.exports